<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> 
                {{ $t("message.party") }} № {{ $route.params.party_id }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <!-- <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input> -->
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <div style="display:flex; float: right;" class="text-right">
                <div class="mr-2" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
                    <el-button v-if="!model.active" size="small" class="btu-color" @click="drawerFilialProducts = true">
                        {{ $t("message.pick_product") }}
                    </el-button>
                    <el-button size="small" class="btu-color" @click="addToSendingList()">
                        {{ $t("message.arrange_delivery") }}
                    </el-button>
                </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div v-loading="loadingData" class="card-table-header table-crm-smart">
        <el-table 
            ref="sendingProductsList"
            header-row-class-name="header__class"
            row-class-name="row__class"
            :data="productList" 
            stripe border
            style="width: 100%">
            <el-table-column
                type="index"
                width="50">
            </el-table-column>
            <el-table-column :label="$t('message.deal')" width="65">
                <template slot-scope="scope"> 
                    {{ scope.row.deal_id }} 
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.reciever')">
                <template slot-scope="scope"> 
                    <span v-html="scope.row.reciever"></span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.partner')">
                <template slot-scope="scope"> 
                    <span v-html="scope.row.partner"></span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.address')">
                <template slot-scope="scope"> 
                    {{scope.row.address}}
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.name')">
                <template slot-scope="scope"> 
                    {{ scope.row.name }}
                    <span v-if="scope.row.type == 'package' && ((scope.row.reciever_id && scope.row.reciever_id.length > 1) || scope.row.address_count > 1)">  
                        <el-tooltip class="item" effect="dark" :content="$t('message.package_includes_multi_direction_deals')" placement="top">
                        <i class="el-icon-warning" style="font-size: 20px; color: brown;"></i>
                        </el-tooltip>
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.barcode')">
                <template slot-scope="scope"> 
                    <span>{{ scope.row.barcode }} </span>    
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.quantity_y')" width="65">
                <template slot-scope="scope"> 
                    <span>{{ scope.row.remainder }} </span>    
                </template>
            </el-table-column>
            <el-table-column :label="$t('message.current_quantity')" width="90">
                <template slot-scope="scope">
                    <el-input
                        :disabled="((scope.row.batches && scope.row.batches.length > 1) || (scope.row.delivery_deal_id && scope.row.delivery_deal_id.length > 0))"
                        :min="0"
                        type="number"
                        size="mini"
                        @input="checkValue(scope.row)"
                        v-model="scope.row.incoming_quantity"
                    ></el-input>
                </template>
            </el-table-column>

            <el-table-column :label="columns.weight.title" width="100">
                <template slot-scope="scope">
                    {{ scope.row.weight | formatNumber(1) }} kg <br> 
                    {{ (scope.row.weight * $kg_to_pound) | formatNumber(1) }} lbs
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.dimension_sm')" width="110">
                <template slot-scope="scope">
                    {{ (scope.row.width || '10') + 'x' + (scope.row.height || '10') + 'x' + (scope.row.length || '10') }}
                </template>
            </el-table-column>

            <el-table-column :label="$t('message.comment')">
                <template slot-scope="scope"> 
                    <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                </template>
            </el-table-column>
            <el-table-column width="60">
                <template slot="header" slot-scope="scope">
                    <i class="el-icon-setting"></i>
                </template>
                <template slot-scope="scope"> 
                    <el-button v-if="!scope.row.delivery_deal_id" @click="removeProductFromParty(scope.row)" style="padding: 10px !important;" type="danger" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
      </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  
               :visible.sync="drawerFilialProducts" 
               ref="drawerFilialProducts"
               size="90%"
               class="bg-se"
               @opened="drawerOpened('drawerFilialProductsChild')"
               @closed="drawerClosed('drawerFilialProductsChild')"
               >
                <div>
                    <filial-products
                        :party_id="$route.params.party_id" 
                        ref="drawerFilialProductsChild" 
                        drawer="drawerFilialProducts"> 
                    </filial-products>
                </div>
            </el-drawer>
            <el-drawer
                :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
                :with-header="false"
                :visible.sync="drawerCDEKForm"
                ref="drawerCDEKForm"
                size="70%"
                class="bg-se"
                :append-to-body="true"
                @opened="drawerOpened('drawerCDEKFormChild')"
                @closed="drawerClosed('drawerCDEKFormChild')"
                >
                <div>
                    <cdek-form
                        :party_id="$route.params.party_id"
                        :partyProduts="sendableProds"
                        ref="drawerCDEKFormChild"
                        drawer="drawerCDEKForm"
                    >
                    </cdek-form>
                </div>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import filialProducts from "./components/incoming-filial-product-list";
import SelectUsers from '@/components/inventory/select-users';
import cdekForm from "./components/cdek-form";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "DeliverParties",
    components: {
        filialProducts,
        SelectUsers,
        cdekForm
    },

    data() {
        return {
            sendableProds: [],
            productList: [],
            successQuantity: false,
            filterForm: {

            },
            drawerFilialProducts: false,
            drawerCDEKForm: false,
            reloadList: false,
        };
    },
    created() {
        this.fetchData();
    },

    computed: {
        ...mapGetters({
          model: "deliveryParties/model",
          columns: "dealProducts/columns",
          pagination: "deliveryParties/product_pagination",
          list: "deliveryParties/product_list",
          mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            show: "deliveryParties/show",
            updateList: "deliveryParties/deliveryPartyProducts",
            deleteProductFromParty: "deliveryParties/deleteProductFromParty",
        }),

        listChanged() {
            this.reloadList = true;
        },

        checkValue(row){
            if(row.incoming_quantity > row.remainder){
                row.incoming_quantity = row.remainder
            }else if (row.incoming_quantity <= 0){
                row.incoming_quantity = 1;
            }
        },

        async fetchData() {
            if (!this.loadingData) {
                this.loadingData = true;
                await this.show(this.$route.params.party_id);
                await this.updateProducts();
            }
        },

        async updateProducts(){
            const query = {id: this.$route.params.party_id , ...this.filterForm};
            await this.updateList(query).then(res => {
                    this.productList = JSON.parse(JSON.stringify(this.list));
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false
                });
        },

        removeProductFromParty(row){
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t("message.warning"), {
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
                type: "warning"
              }
            )
            .then(() => {
                this.deleteProductFromParty({id: row.id, type: row.type})
                    .then(res => {
                        this.$alert(res);
                        this.updateProducts();
                    }).catch(err => {
                    })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t("message.operation_canceled")
              });
            });
           
        },

        addToSendingList() {
            this.sendableProds = this.productList.filter(el => !el.delivery_deal_id);
            if(this.sendableProds && this.sendableProds.length > 0){
                this.drawerCDEKForm = true;
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.empty_party')
                });
            }
        },

        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.reloadList = false;
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        
    },

    beforeRouteLeave (to, from, next) {
        this.$store.commit('cdek/EMPTY_LIST');
        next()
    },
};
</script>
<style>
   .mm_center_items{
      justify-content: center;
      align-items: center;
   }
</style>

